import React from 'react';
import {  Button } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Txt from './Txt'
function DialogAlert(props) { 

    //const [isOpen, setOpen] = React.useState(props.open);

    const handleClose = () => {
        props.onClose();
      };

 
    return (
    <Dialog
    open={props.open} 
    onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{props.tituloMensaje}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.textoMensaje}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
           <Txt text={["Cerrar", "Close", "Fechar"]} />
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default DialogAlert;