import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../components/Header'
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import { useAppContext } from "../libs/contextLib";
import 'fontsource-roboto';
import './styles/Home.css'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GaleriaImgs from '../components/GaleriaImgs';
import { SubsectionContainer } from './SubsectionContainer';

function ContentContainer(props) {
    const theme = useTheme();
    const [contentData, setContentData] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);
     //Manejo de idioma
    const { actualLanguage } = useAppContext();

    React.useEffect(() => {
        setContentData(JSON.parse(window.localStorage.getItem('contentData')))
     }, []);
    
    React.useEffect(() => {
        setLoaded(false);
    }, [props.match.params.id]);

     const breadcrumbsBar = () => {

        if(contentData===null){
            return (null)
        }
        const txt_title='nombre_'+getLan(actualLanguage)
        const title=contentData.data[props.match.params.id][txt_title]

        return (
        <Breadcrumbs maxItems={2} aria-label="breadcrumb" style={{marginTop: theme.spacing(1),marginLeft: theme.spacing(1) }}>
        <Link color="inherit" to="/" >
          App
        </Link>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs> )
     }

     const data = () => {

        if(contentData===null){
            return (<div>Cargando...</div>)
        }

        const txt_content='contenido_'+getLan(actualLanguage)
        const content=contentData.data[props.match.params.id]['data_seccion'][txt_content]
        const subsecction = contentData.data[props.match.params.id]['data_subseccion'];
        const language = getLan(actualLanguage);

        return (
        <React.Fragment>
        {imagenes()}
        <Box textAlign="center">
        <div dangerouslySetInnerHTML={{__html: content}}></div>

            {
                (subsecction) && <SubsectionContainer subsecction={subsecction} language={language} />
                    
            }
            
        </Box>
        
        </React.Fragment>
        )
     }

     const getLan= (language) => {
         if(language==='pt'){
             return 'por'
         }
         else{
             return language;
         }
     }

     const imagenes= () => {

        if(contentData===null){
            return (null)
        }
        const path=contentData.images_path;
        const txt_title='nombre_'+getLan(actualLanguage)
        const title=contentData.data[props.match.params.id][txt_title]
        const cantidadImagenes=contentData.data[props.match.params.id]['data_seccion']['imagenes'].length

        if(cantidadImagenes===0){
            return (null)
        }
        if(cantidadImagenes===1){
            var imgFileName=path+'/'+contentData.data[props.match.params.id]['data_seccion']['imagenes'][0]['nombre']
            return (
                <Box textAlign="center">
                {loaded ? null : ( <div>Cargando...</div> )}
                <img src={imgFileName} alt={title} className="HomeImg" style={loaded ? {} : { display: 'none' }} onLoad={() => setLoaded(true)}></img>
                
                </Box>
            )

        }
        else{
            const listaImagenes=contentData.data[props.match.params.id]['data_seccion']['imagenes']
            return (<GaleriaImgs imagenes={listaImagenes} path={path} setLoaded={setLoaded} loaded={loaded}></GaleriaImgs>)
        }


     }

return (
    <React.Fragment>
    <CssBaseline />
    <Header></Header>
    { breadcrumbsBar()}
    <Container style={{marginTop: theme.spacing(4) }}>

    {data()}
    </Container>

    </React.Fragment>
    );
}


export default ContentContainer;