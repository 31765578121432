import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../components/Header'
import Box from '@material-ui/core/Box';
import 'fontsource-roboto';
import './styles/Home.css'



function Home() { 
 
  return (
    <React.Fragment>
    <CssBaseline />
    <Header></Header>
    <Box>
        <img src="./images/home.jpg" alt="Home" className="HomeImg"></img>
    </Box>
    <Box textAlign="center">
        <h3>Ejemplo de imagen y texto</h3>
    </Box>
    </React.Fragment>
  );
}


export default Home;