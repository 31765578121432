import React from "react";
import { useAppContext } from "../libs/contextLib";
import { Typography } from '@material-ui/core';

export default function Txt (props) {

      //Manejo de si el user está autenticado o no de variables de contexto
     const { actualLanguage } = useAppContext();
     const renderText = (textArray) => {
        switch(actualLanguage) {
          case 'es':
            return textArray[0];
            break;
            case 'en':
                return textArray[1];
            break;
            case 'pt':
                return textArray[2];
            break;
          default:
            return textArray[0];
        }
    }
    return (
                
        <Typography>  {renderText(props.text)} </Typography>                 
    )

   
}