import React, { useState } from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const SubsectionContainer = ({ subsecction, language }) => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => ( event, isExpanded ) => {
        setExpanded(isExpanded ? panel : false);
    };

    const txt_title='nombre_'+language;
    const txt_content='contenido_'+language;

    return (
        <>
            {
                subsecction.map( item => (
                    <Accordion key={ item.id } expanded={expanded === item.id } onChange={handleChange(item.id)} className="mb-2 pb-10">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography component={'span'}>{ item[txt_title] }</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component={'span'}>
                            <div dangerouslySetInnerHTML={{__html: item[txt_content] }}></div>
                            </Typography>
                            </AccordionDetails>
                    </Accordion>
                ))
            }
        </>
    )
}
