import React, {useState,useEffect } from 'react';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/core/styles';
import { AppContext } from "./libs/contextLib";
import {HashRouter ,Route, Switch,Redirect} from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import { CookiesProvider,useCookies } from 'react-cookie';
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ConfigData from './config';
import NotFound from './pages/NotFound';
import axios from 'axios'
import ContentContainer from './pages/ContentContainer'



function App() {



  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [actualLanguage,setUserLanguage]=useState('xx')
  const [cookies, setCookie] = useCookies();

  //EJECUCION UNA UNICA VEZ AL CARGAR LA APLICACION, REAUTENTICA POR SESION
  useEffect(() => {
    async function onLoad() {
      console.log('OnLoad '+process.env.REACT_APP_STAGE + ' '+process.env.NODE_ENV)
      //AUTENTICACION
      //if(cookies.user_token){
      //  console.log("Has Token")
        userHasAuthenticated(true);
        //if(window.localStorage.getItem('contentData')===null){
          axios.get(ConfigData.BackendPathURL+'/api/getAppData')
          .then(function (response) {
            console.log(response.data);
            if(response.data !== undefined){
                window.localStorage.setItem('contentData',JSON.stringify(response.data))
                console.log("Data loaded")
            }
            else{
               console.log("Error en response")
            }

          })
          .catch(function (error) {
                console.log(error.response);

          });
        //}

      /*}
      else{
        userHasAuthenticated(false);
      }*/
      //IDIOMA
      if(cookies.user_lan){
        console.log("yes cookie")
        console.log(cookies.user_lan)
        setUserLanguage(cookies.user_lan);
      }
      else{
        console.log("no cookie")
        var lan=navigator.language || navigator.userLanguage
        var res = lan.split("-");

        setUserLanguage(res[0]);
      }

    }
    onLoad();
  }, []);

  const theme = createMuiTheme({"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(137,185,89,1)","main":"rgba(0,115,32)","dark":"rgba(0,34,0) ","contrastText":"#fff"},"secondary":{"light":"rgba(134, 70, 137, 1)","main":"rgba(94, 52, 110, 1)","dark":"rgba(69, 43, 89, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}});


  return (
    <ThemeProvider theme={theme}>
    <CookiesProvider>
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated,actualLanguage,setUserLanguage}}>
    <HashRouter  basename={ConfigData.basename}>
    <Switch>
      <UnauthenticatedRoute path="/login">
          <Login></Login>
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/">
      <Redirect to="/s/0" />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact  path="/home">
      <Redirect to="/s/0" />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/s/:id" component={ContentContainer} >

      </AuthenticatedRoute>

      <Route path="*">
        <NotFound></NotFound>
        </Route>
    </Switch>
    </HashRouter >
    </AppContext.Provider>
    </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
