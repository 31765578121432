import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../components/Header'
import { Box,  Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import 'fontsource-roboto';
import './styles/Home.css'
import Txt from '../components/Txt'


function NotFound() { 
 
  return (
    <React.Fragment>
    <CssBaseline />
    <Header></Header>
    
    <Box textAlign="center">
        <h2><Txt text={["Ooops! No encontramos el contenido que buscas...", "We can't find the content you're looking for", "Não conseguimos encontrar o conteúdo que você procura"]} /></h2>
    </Box>
    <Box textAlign="center">
    <Button variant="outlined" color="primary" style={{ textTransform: "none" }} component={Link} to={'/home'}><Txt text={["Volver", "Go back", "Retorna"]} /></Button>
     </Box>
    </React.Fragment>
  );
}


export default NotFound;