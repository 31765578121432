import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Grid, TextField, Button} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Txt from './Txt'
import Lng from '../libs/Lang'
import { useAppContext } from "../libs/contextLib";

function DialogRememberPass(props) { 

    const handleClose = () => {
        props.onClose();
      };

      const { actualLanguage } = useAppContext();
 
    return (
        <Dialog  open={props.open} onClose={handleClose}>
        <DialogTitle ><Txt text={['Recordar Contraseña','Remember password','Lembre-se de senha']}/></DialogTitle>
        <Container style={{ marginTop: '10px', marginBottom:'20px' }}>
                <Box textAlign="center">
                    <h3><Txt text={['Ingrese su email, y la contraseña le será enviada a su correo','Your password will be sent to your email address','Digite seu email, e a senha será enviada para seu email']}/></h3>
                </Box>
                <Grid>
                       
                    <TextField id="username" label={Lng['Usuario'][actualLanguage]} type="email" fullWidth autoFocus required /> 
                        
                </Grid>
                <Grid container justify="center" style={{ marginTop: '10px' }}>
                        <Button variant="outlined" color="primary" onClick={handleClose}  style={{ textTransform: "none" }}><Txt text={['Enviar','Send','Enviar']}/></Button>
                </Grid>
        </Container>
       

      </Dialog>
    );
  }
  
  
  export default DialogRememberPass;