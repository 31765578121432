import React from 'react';
import { Paper,Box, Button, AppBar } from '@material-ui/core';
import Txt from './Txt'
import useIosInstallPrompt from '../hooks/useIosInstallPrompt';
import useWebInstallPrompt from '../hooks/useWebInstallPrompt';

function InstallPWA  ()  {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();


  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  return (
        <div>
          {iosInstallPrompt && (
            <AppBar position="static" color="primary">
            <Box textAlign="center" style={{ margin: '10px'}}>
            <p><Txt text={["Instala esta aplicación para una mejor experiencia", "Install this application on your homescreen for a better experience.", "Instale este aplicativo em sua tela inicial para uma melhor experiência."]} /></p>
            <Button variant="contained" >
            <Txt text={["Presiona", "Tap", "Tocar"]} />&nbsp;&nbsp;
                        <img
                            src="/images/AppleShare.png"
                            style={{margiLeft: "6px",marginRight:"6px"}}
                            alt="Add to homescreen"
                            height="20"
                            width="20"
                            />
                        &nbsp;<Txt text={["luego", "then", "então"]} /> &quot;<Txt text={["Agregar a la pantalla principal", "Add to Home Screen", "Adicionar à tela inicial"]} />&quot;
                        </Button>
            </Box>
            </AppBar>
          )}
          {webInstallPrompt && (
            <AppBar position="static" color="primary">
            <Box textAlign="center" style={{ margin: '10px'}}>
            <p className="uk-h5 uk-text-muted uk-text-center uk-margin-remove-top"><Txt text={["Instala esta aplicación para una mejor experiencia", "Install this application on your homescreen for a better experience.", "Instale este aplicativo em sua tela inicial para uma melhor experiência."]} /></p>
            <Button variant="contained"  onClick={handleWebInstallAccepted}><Txt text={["Instalar la aplicación", "Install the application", "Instale o aplicativo"]} /></Button>
            </Box>
            </AppBar>
          )}
          </div>
  );
}
export default InstallPWA;
