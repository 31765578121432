import React from 'react';
import Container from '@material-ui/core/Container';
import 'fontsource-roboto';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Txt from './Txt'
const useStyles = makeStyles((theme) => ({
    root: {
     flexGrow: 1,
    },
    header: {
     display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      margin: 'auto',
      maxWidth: 400,
      overflow: 'hidden',
      display: 'block',
      width: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }));

function GaleriaImgs({imagenes,path,setLoaded,loaded}) { 

  const [imagenActiva, setImagenActiva] = React.useState(0);
  const maxSteps = imagenes.length;

  const [openBackdrop, setOpenBackDrop] = React.useState(false);

  const handleOnLoad = () => {
    console.log('entra Handle')
    setOpenBackDrop(false);
    setLoaded(true);
  }

  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };
  
  const handleNext = () => {
    setOpenBackDrop(true);
    setTimeout(function() { //Start the timer
        setImagenActiva((prevActiveStep) => prevActiveStep + 1);
    }, 1000)
    
  };

  const handleBack = () => {
    setOpenBackDrop(true);
    setTimeout(function() { //Start the timer
        setImagenActiva((prevActiveStep) => prevActiveStep - 1);
    }, 1000)
  };
  const classes = useStyles();
  const theme = useTheme();


 
  return (

    <React.Fragment>
    
      {loaded ? null : ( <div>Cargando...</div> )}
      <Container maxWidth="sm" style={loaded ? {} : { display: 'none' }}>
          <Fade in={true}>
      <img
        className={classes.img}
        src={path+"/"+imagenes[imagenActiva].nombre}
        alt={imagenes[imagenActiva].descripcion}
        onLoad={() => handleOnLoad() }
      />
      </Fade>
      </Container>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={imagenActiva}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={imagenActiva === maxSteps - 1}>
             <Txt text={["Siguiente", "Next", "Seguinte"]} />
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={imagenActiva === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            <Txt text={["Anterior", "Previous", "Anterior"]} />
          </Button>
        }
      />
    
    
    
    <Backdrop className={classes.backdrop}  open={openBackdrop} onClick={handleCloseBackDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>



  );
}


export default GaleriaImgs;