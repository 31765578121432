
const ConfigData_dev={
    simulateAPI:false,
    basename:"",
    SystemPathURL:'http://localhost:3000',
    BackendPathURL:'http://127.0.0.1/HG/BEN/soft/APAPAU_BE_GITHUB/apapau-app-hotel/public',
}

const ConfigData_predeploy={
    simulateAPI:false,
    basename:"/HG/BEN/soft/AppFrontEndAPAPAU_GIT/jsa-software-git.apapau-app-hotel-frontend/build",
    SystemPathURL:'http://127.0.0.1',
    BackendPathURL:'http://127.0.0.1/HG/BEN/soft/APAPAU_BE_GITHUB/apapau-app-hotel/public',
}

const ConfigData_prod={
    simulateAPI:false,
    basename:"",
    SystemPathURL:'https://app.apapaubrasil.com',
    BackendPathURL:'https://back.apapaubrasil.com',
}

var ConfigData=null
if(process.env.REACT_APP_STAGE === 'dev'){
    ConfigData = ConfigData_dev
}
if(process.env.REACT_APP_STAGE === 'predeploy'){
    ConfigData = ConfigData_predeploy
}
if(process.env.REACT_APP_STAGE === 'prod'){
    ConfigData = ConfigData_prod
}
if(ConfigData==null){
    //DEFAULT
    ConfigData = ConfigData_dev
}


export default ConfigData