import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LanguageIcon from '@material-ui/icons/Language';
import { useAppContext } from "../libs/contextLib";
import { useCookies } from 'react-cookie';
import Lng from '../libs/Lang'


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
});

function Header (props) {
  //Estilos
  const classes = useStyles();

  //Manejo del menú desplegable lateral y menú desplegable de idioma
  const [state, setState] = React.useState({
    isDrawerOpen: false,
    isMenuLanOpen:false
  });
  //Manejo del punto de anclaje del menú desplegable de idioma
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Manejo de si el user está autenticado o no de variables de contexto
  const { isAuthenticated,userHasAuthenticated,actualLanguage,setUserLanguage } = useAppContext();
  //Herramienta para redireccionar
  const history = useHistory();
  //Manejo de cookies
  const [cookies, setCookie, removeCookie] = useCookies();

  const [contentData, setContentData] = React.useState(null);


  //Abrir o cerrar menú lateral
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, isDrawerOpen: open });
  };

  //Logout, se borra la cookie y redirect
  const handleLogout= () => {
    userHasAuthenticated(false)
    removeCookie('user_token');
    removeCookie('user_data');
    history.push("./login");
  }


  //Abrir o cerrar menú de idioma
  const toggleMenuLan= (open) => (event) => {
    setState({ ...state, isMenuLanOpen: open });
    if(open){
      setAnchorEl(event.currentTarget);
    }
    else{
      setAnchorEl(null);
    }
  };

  const handleChangeLanguage=(lan) =>{
    console.log("new lan:"+lan);
    setCookie('user_lan', lan);
    setUserLanguage(lan)
    setState({ ...state, isMenuLanOpen: false })
  }

  const handleItemMenu=(id)=>{
    console.log(contentData.data[id].nombre_es);
  }

  const getLan= (language) => {
    if(language=='pt'){
        return 'por'
    }
    else{
        return language;
    }
}




//Items del menú
  const list = () => (
    <div
      className='top'
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >

      {(contentData===null)
    ?
      <List className={classes.list}>
      <ListItem button component={Link} to="/home" >
            <ListItemIcon><ChevronRightIcon /></ListItemIcon>
            <ListItemText primary="Página Suelta"   />
          </ListItem>
          <ListItem button component={Link} to="/galeria" >
            <ListItemIcon><ChevronRightIcon /></ListItemIcon>
            <ListItemText primary="Página con galería de imágenes" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><ChevronRightIcon /></ListItemIcon>
            <ListItemText primary={Lng['Salir'][actualLanguage]}/>
          </ListItem>
          </List>
        :
        <List className={classes.list}>
        {contentData.data.map((item,index)=>{
          const txt_title='nombre_'+getLan(actualLanguage)
          return  <ListItem button component={Link} to={`/s/`+index} key={index}>
          <ListItemIcon><ChevronRightIcon /></ListItemIcon>
          <ListItemText primary={item[txt_title]}/>
        </ListItem> ;
          }
        )}

      </List>
      }

      <Divider />

    </div>
  );

  React.useEffect(() => {
     setContentData(JSON.parse(window.localStorage.getItem('contentData')))

  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>

          <img src="/logo-header.png" alt="APA PAU" className={classes.logo}/>
          <Typography variant="h6" className={classes.title}>
           Hotel Apa Pau Brasil
          </Typography>

          <div className={classes.toolbarButtons}>
             {/*} <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"

                color="inherit"
              >
                <AccountCircle />
  </IconButton>*/}
              <IconButton
              onClick={toggleMenuLan(true)}
               color="inherit"
              >
                <LanguageIcon />
                <Typography variant="body1">{actualLanguage}</Typography>
              </IconButton>
              <Menu
                id="menu-lan"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={state.isMenuLanOpen}
                onClose={toggleMenuLan(false)}

              >
                <MenuItem onClick={() => handleChangeLanguage('es')}>ES</MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('pt')} >PT</MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('en')} >EN</MenuItem>
              </Menu>
            </div>

        </Toolbar>
      </AppBar>
    <div>
    {isAuthenticated
    ?
          <Drawer anchor={`left`} open={state.isDrawerOpen} onClose={toggleDrawer(false)}>
            {list()}
          </Drawer>
    :
        <Drawer anchor={`left`} open={state.isDrawerOpen} onClose={toggleDrawer(false)}>
       <ListItem button component={props => <Link to="/login" {...props}/>} >
            <ListItemIcon><ChevronRightIcon /></ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
      </Drawer>
    }
    </div>
    </div>
  );
}

export default Header