import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../components/Header'
import 'fontsource-roboto';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Paper,  Grid, TextField, Button, FormControlLabel, Checkbox, Toolbar, AppBar, Typography } from '@material-ui/core';
import DialogRememberPass from '../components/DialogRememberPass';
import { useAppContext } from "../libs/contextLib";
import { useCookies } from 'react-cookie';
import ConfigData from '../config'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogAlert from '../components/DialogAlert';
import Txt from '../components/Txt'
import Lng from '../libs/Lang'
import InstallPWA from '../components/InstallPWA';

function Login() {

    //Manejo del modal de recordar contraseña
    const [openRememberPass, setOpenRememberPass] = React.useState(false);
    //Manejo de datos del form d elogin
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
     //Manejo de si el user está autenticado o no de variables de contexto
    const { userHasAuthenticated,actualLanguage } = useAppContext();
    //Manejo de cookies
    const [cookies, setCookie] = useCookies();
    //Manejo del icono de loading
    const [isLoading, setLoading] = useState(false);
    //Manejo del modal de alerta
    const [openAlert, setOpenAlert] = React.useState(false);
    const [tituloAlert, setTituloAlert] = React.useState("");
    const [textoAlert, setTextoAlert] = React.useState("");



    useEffect( () => {
        userHasAuthenticated(true);
    },
    [],
    );

    //Apertura Dialog Recordar Contraseña
    const handleClickOpenRememberPass = () => {
        setOpenRememberPass(true);
      };

    //Cierre Dialog Recordar Contraseña
    const handleCloseRememberPass = (value) => {
        setOpenRememberPass(false);
      };

     //Cierre de Dialog de alerta
    const handleCloseAlert = () => {
        setOpenAlert(false)
    }

    //Login
    const handleClickLogin = (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            //Login contra servicio
            //console.log("User: "+email);
           // console.log("Pass: "+password);
           if(ConfigData.simulateAPI==true){
                if(email==='test' && password=='test'){
                    setCookie('user_token', 'token');
                    setCookie('user_data', JSON.stringify({name:"John"}));
                    userHasAuthenticated(true);
                }
                else{
                    setLoading(false);
                    setTituloAlert(Lng['Error'][actualLanguage])
                    setTextoAlert(Lng['Error de login'][actualLanguage])
                    setOpenAlert(true);
                }
                return
           }

            axios.post(ConfigData.BackendPathURL+'/api/login', {
                user: email,
                pass: password
              })
              .then(function (response) {
                console.log(response.data);
                if(response.data.token !== undefined){
                    setCookie('user_token', JSON.stringify(response.data.token));
                    setCookie('user_data', JSON.stringify(response.data.user));
                    userHasAuthenticated(true);
                    axios.get(ConfigData.BackendPathURL+'/api/getAppData')
                    .then(function (response) {
                      console.log(response.data);
                      if(response.data !== undefined){
                          window.localStorage.setItem('contentData',JSON.stringify(response.data))
                          console.log("Data loaded")
                          window.location.href = "/";
                      }
                      else{
                         console.log("Error en response")
                      }

                    })
                    .catch(function (error) {
                          console.log(error.response);

                    });

                }
                else{

                    setLoading(false);
                    setTituloAlert(Lng['Error'][actualLanguage])
                    setTextoAlert(Lng['Error de login'][actualLanguage])
                    setOpenAlert(true);
                }

              })
              .catch(function (error) {
                    console.log(error.response);
                    setLoading(false);
                    setTituloAlert(Lng['Error'][actualLanguage])
                    setTextoAlert(Lng['Error de login'][actualLanguage])
                    setOpenAlert(true);
              });
            //
           //

        } catch (e) {
            //alert(e.message);
            setTituloAlert(Lng['Error'][actualLanguage])
            setTextoAlert(Lng['Error de login'][actualLanguage])
            setOpenAlert(true);
        }
    }



    return (
      <React.Fragment>
      <CssBaseline />
      <Header showMenu="false"></Header>
      <Grid container justify = "center">
      <Paper style={{marginTop:'10px',marginLeft:'20px',marginRight:'20px',marginBottom:'20px',maxWidth:400}}>
      <Container style={{paddingBottom:'20px'}}>
                <Box textAlign="center">
                    <h2><Txt text={["Ingresar", "Login", "Entrar"]} /></h2>
                </Box>
                    <Grid>

                            <TextField id="username" label={Lng['Usuario'][actualLanguage]} type="email" onChange={e => setEmail(e.target.value)}
 fullWidth autoFocus required />

                    </Grid>
                    <Grid>

                            <TextField id="userpass" label={Lng['Contraseña'][actualLanguage]} type="password" onChange={e => setPassword(e.target.value)}
 fullWidth required />

                    </Grid>
                   {/*  <Grid container alignItems="center" justify="space-between">
                        <Grid item>
                            <FormControlLabel control={
                                <Checkbox
                                    color="primary"
                                />
                            } label="Remember me" />
                        </Grid>
                        <Grid item>
                            <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary" onClick={handleClickOpenRememberPass}>Forgot password ?</Button>
                        </Grid>
                        </Grid>*/ }
                    <Grid container justify="center" style={{ marginTop: '10px',marginBottom: '10px' }}>
                        <Button variant="outlined" color="primary" style={{ textTransform: "none" }} onClick={handleClickLogin}> {isLoading  ?<CircularProgress /> : <Txt text={["Ingresar", "Login", "Entrar"]} /> }</Button>
                    </Grid>
                </Container>
            </Paper>
            </Grid>
            <InstallPWA></InstallPWA>
            <DialogRememberPass open={openRememberPass} onClose={handleCloseRememberPass} />
            <DialogAlert open={openAlert}  tituloMensaje={tituloAlert} onClose={handleCloseAlert} textoMensaje={textoAlert}/>

      </React.Fragment>
    );
  }


  export default Login;